export default function Faqs() {
  return (
    <section className="bg-violet-300" id="faq">
      <div className="py-24 px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
        <div className="flex flex-col text-left basis-1/2">
          <p className="sm:text-4xl text-3xl font-extrabold text-violet-800">
            Frequently Asked Questions
          </p>
        </div>
        <div className="basis-1/2">
          <div className="collapse collapse-plus bg-white">
            <input id="platform-designed-for" type="radio" name="my-accordion-3" />
            <div aria-label="What is your platform designed for?" className="collapse-title text-xl font-bold text-violet-800">
              <label htmlFor="platform-designed-for">
                What is your platform designed for?
              </label>
            </div>
            <div className="collapse-content">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed text-violet-800">
                  Our platform is designed to streamline the process of creating personalized posters for Employee Day celebrations in companies with up to 200 employees. It allows designers to easily generate customized posters by uploading a single template and each employee's profile photo.
                </div>
              </div>
            </div>
          </div>
          <div className="collapse collapse-plus bg-white mt-1">
            <input id="Do-I-need-any-design-skills-to-use-your-platform?" type="radio" name="my-accordion-3" />
            <div className="collapse-title text-xl font-bold text-violet-800">
              <label htmlFor="Do-I-need-any-design-skills-to-use-your-platform?">
                Do I need any design skills to use your platform?
              </label>
            </div>
            <div className="collapse-content">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed text-violet-800">
                  No, you don't need any advanced design skills. Our platform simplifies the process, eliminating the need for complex design software or AI tools. Designers can easily upload a template and generate personalized posters with just a few clicks.
                </div>
              </div>
            </div>
          </div>
          <div className="collapse collapse-plus bg-white mt-1">
            <input id="How-does-your-platform-work?" type="radio" name="my-accordion-3" />
            <div className="collapse-title text-xl font-bold text-violet-800">
              <label htmlFor="How-does-your-platform-work?">
                How does your platform work?
              </ label>
            </div>
            <div className="collapse-content">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed text-violet-800">
                  Our platform allows designers to upload a single poster template. They can then generate personalized posters for each employee by simply uploading their profile photo. The platform automates the repetitive design tasks, saving time and resources.
                </div>
              </div>
            </div>
          </div>
          <div className="collapse collapse-plus bg-white mt-1">
            <input id="Is-your-platform-user-friendly?" type="radio" name="my-accordion-3" />
            <div className="collapse-title text-xl font-bold text-violet-800">
              <label htmlFor="Is-your-platform-user-friendly?">
                Is your platform user-friendly?
              </label>
            </div>
            <div className="collapse-content">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed text-violet-800">
                  Yes, our platform is designed with user-friendliness in mind. It's intuitive and easy to use, making it accessible for both experienced designers and those with limited design skills.
                </div>
              </div>
            </div>
          </div>
          <div className="collapse collapse-plus bg-white mt-1">
            <input id="Can-I-customize-the-poster-templates?" type="radio" name="my-accordion-3" />
            <div className="collapse-title text-xl font-bold text-violet-800">
              <label htmlFor="Can-I-customize-the-poster-templates?">
                Can I customize the poster templates?
              </label>
            </div>
            <div className="collapse-content">
              <div className="pb-5 leading-relaxed">
                <div className="space-y-2 leading-relaxed text-violet-800">
                  <p>For now you can customize the following:</p>
                  <ul className="list list-disc list-inside">
                    <li className="list-item">Poster title</li>
                    <li className="list-item">Poster Image</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
