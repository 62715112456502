"use client"

import dynamic from 'next/dynamic';
import { toast } from 'react-toastify';
import { useRouter } from 'next/navigation';
import Utils from '@/lib/Utils';
import { PLAN_TYPE } from '@/lib/constant';
import { useEffect, useState } from 'react';
const PricingCard = dynamic(() => import('./PricingCard'), {
    ssr: false
});


export default function Pricing({ user }) {
    const router = useRouter();
    const activeUser = Utils.getCurrentUser();
    const userInfo = activeUser?.user || {};

    const [currUser, setCurrUser] = useState(userInfo);

    useEffect(() => {
        setCurrUser(user?.user);
    }, [user]);

    const checkUserLogin = async (plan) => {

        console.log(plan);
        if (!currUser) {
            toast.warning('Please Login or SignUp to continue!');
            return router.push('/signin');
        }

        if (currUser?.plan === PLAN_TYPE.GOLD) {
            toast.warning('Gold Plan is forever for you!');
            return;
        }

        if (plan === PLAN_TYPE.TRIAL) {
            toast.warning('You are already on Free Plan!');
            return;
        }

        const id = toast.loading("Please wait...")

        try {
            const response = await fetch('/api/checkout_sessions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: currUser?.email, plan }),
            });

            const data = await response.json();

            if (data?.type !== "error") {
                console.log("data ----> ", data);
                toast.update(id, { render: "Redirecting to Checkout!", type: "success", isLoading: false });
                router.replace(data?.response);
            }

            toast.update(id, { render: data?.message || "Please try again later!", type: "error", isLoading: false });
        } catch (error) {
            console.log("error ----> ", error.message);
            toast.update(id, { render: error?.message || "Something went wrong!", type: "error", isLoading: false });
        }
    };

    const PlanTitle = currUser?.plan === PLAN_TYPE.GOLD ? 'Active Plan' : 'Most Popular';

    const silverFeatures = [
        'Single Template Upload',
        'Profile Photo Integration',
        'Two-Click Poster Generation',
        '5 posters, per day',
    ];

    const goldFeatures = [
        '19 Template Uploads',
        'Customizable Design Options',
        'Unlimited Poster Generation',
        'Two-Click Poster Generation',
    ];


    return (
        <div id="pricing" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            {/* Common JSX */}
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className=" text-violet-900 max-w-lg mb-6 font-sans text-5xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto" data-aos="zoom-y-out">
                    <span className="relative inline-block">
                        <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                            <defs>
                                <pattern id="7e5e8ff8-1960-4094-a63a-2a0c0f922d69" x="0" y="0" width=".135" height=".30">
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect fill="url(#7e5e8ff8-1960-4094-a63a-2a0c0f922d69)" width="52" height="24" />
                        </svg>
                        <span className="relative text-6xl">Transparent</span>
                    </span>{' '}
                    pricing.
                </h2>
                <p className="text-base text-gray-700 md:text-lg" data-aos="zoom-y-out" data-aos-delay="160">
                    Benefit from clear and flexible pricing as your business expands.
                </p>
            </div>


            <div className="grid gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-2 xl:max-w-screen-lg sm:mx-auto" data-aos="zoom-in-left" data-aos-delay="200">
                {/* Pricing cards */}
                <PricingCard
                    plan="Silver"
                    price="FREE"
                    features={silverFeatures}
                    buttonText="Start with Free 1 Month"
                    onClick={() => checkUserLogin(PLAN_TYPE.TRIAL)}
                />
                <PricingCard
                    PlanTitle={PlanTitle}
                    userPlan={currUser?.plan}
                    plan="Gold"
                    price="$19"
                    features={goldFeatures}
                    buttonText="Buy Pro"
                    onClick={() => checkUserLogin(PLAN_TYPE.GOLD)}
                />
            </div>
        </div>
    );
}
