"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import Aos from "aos";

import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Hero from "@/components/Hero";
import WhyYouNeed from "@/components/WhyYouNeed";
import Pricing from "@/components/Pricing";
import HowItWorks from "@/components/HowItWorks";
import Faqs from "@/components/Faqs";
import { useUserStore } from "@/lib/store/user";
import FeedBack from "@/components/FeedBack";

export default function Home() {
  const pathname = usePathname();
  const user = useUserStore(state => state.user)

  useEffect(() => {
    Aos.init({
      once: true,
      disable: "mobile",
      duration: 600,
      easing: "ease-out-cubic",
    });
  });

  return (
    <>
      <Header key={pathname} />
      <main>
        <Hero />
        <WhyYouNeed />
        <HowItWorks />
        <Faqs />
        <Pricing user={user} />
        <FeedBack />
      </main>
      <Footer />
    </>
  );
}
