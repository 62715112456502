import React from 'react'

const FeedBack = () => {
    return (
        <section className="bg-violet-900 text-neutral-content">
            <div className="max-w-7xl mx-auto px-8 py-16 md:py-40 text-center">
                <h2 className="font-extrabold mb-6 md:mb-8 text-6xl text-white">
                    Top FeedBack
                </h2>
                <div className='flex justify-center'>
                    <iframe className='shadow-xl' style={{ border: "none" }} src="https://cards.producthunt.com/cards/comments/3538956?v=1" width="500" height="405" allowFullScreen></iframe>
                </div>
            </div>
        </section>
    )
}

export default FeedBack